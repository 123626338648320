import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {useEffect, useState} from "react";
import AOS from "aos";
import Isotope from "isotope-layout";
import PortfolioCard from "../components/PortfolioCard";

import Lightbox from "yet-another-react-lightbox";
import Counter from "yet-another-react-lightbox/plugins/counter";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/plugins/captions.css";

export default function ImagePortfolio() {
    const [isotope, setIsotope] = useState<Isotope>();
    const [filterKey, setFilterKey] = useState("filter-event2");
    const [openLightbox, setOpenLightbox] = useState(false);
    const [currentImages, setCurrentImages] = useState([]);
    const [currentLightboxIndex, setCurrentLightboxIndex] = useState(0);

    const images = [
        {id: 1, title: "Aufbau", description: "", filter: "filter-event1", src: require("../gfx/imagePortfolio/event1/A1.jpg")},
        {id: 2, title: "Aufbau", description: "", filter: "filter-event1", src: require("../gfx/imagePortfolio/event1/A2.jpg")},
        {id: 3, title: "Aufbau", description: "", filter: "filter-event1", src: require("../gfx/imagePortfolio/event1/A3.jpg")},
        {id: 4, title: "Aufbau", description: "", filter: "filter-event1", src: require("../gfx/imagePortfolio/event1/A4.jpg")},
        {id: 5, title: "Aufbau", description: "", filter: "filter-event1", src: require("../gfx/imagePortfolio/event1/A5.jpg")},
        {id: 6, title: "Markttag", description: "", filter: "filter-event2", src: require("../gfx/imagePortfolio/event2/B1.jpeg")},
        {id: 7, title: "Markttag", description: "", filter: "filter-event2", src: require("../gfx/imagePortfolio/event2/B2.jpeg")},
        {id: 8, title: "Markttag", description: "", filter: "filter-event2", src: require("../gfx/imagePortfolio/event2/B3.jpeg")},
        {id: 9, title: "Markttag", description: "", filter: "filter-event2", src: require("../gfx/imagePortfolio/event2/B4.jpeg")},
        {id: 10, title: "Markttag", description: "", filter: "filter-event2", src: require("../gfx/imagePortfolio/event2/B5.jpeg")},
        {id: 11, title: "Markttag", description: "", filter: "filter-event2", src: require("../gfx/imagePortfolio/event2/B6.jpeg")},
        {id: 12, title: "Markttag", description: "", filter: "filter-event2", src: require("../gfx/imagePortfolio/event2/B7.jpeg")},
        {id: 13, title: "Markttag", description: "", filter: "filter-event2", src: require("../gfx/imagePortfolio/event2/B8.jpeg")},
        {id: 14, title: "Markttag", description: "", filter: "filter-event2", src: require("../gfx/imagePortfolio/event2/B9.jpeg")},
        {id: 15, title: "Markttag", description: "", filter: "filter-event2", src: require("../gfx/imagePortfolio/event2/B10.jpeg")},
        {id: 16, title: "Markttag", description: "", filter: "filter-event2", src: require("../gfx/imagePortfolio/event2/B11.jpeg")},
        {id: 17, title: "Fest", description: "", filter: "filter-event3", src: require("../gfx/imagePortfolio/event3/C1.jpg")},
        {id: 18, title: "Schafe", description: "", filter: "filter-event3", src: require("../gfx/imagePortfolio/event3/C2.jpg")},
        {id: 19, title: "Schafe", description: "", filter: "filter-event3", src: require("../gfx/imagePortfolio/event3/C3.jpg")},
        {id: 20, title: "Schafe", description: "", filter: "filter-event3", src: require("../gfx/imagePortfolio/event3/C4.jpg")},
        {id: 21, title: "Wildblumenwiese", description: "", filter: "filter-event3", src: require("../gfx/imagePortfolio/event3/C5.jpg")}
    ];

    const handlePortfolioButtonClick = (imageID: number) => {
        let temp: any = [];
        if(filterKey === "*") {
            images.forEach(object => {
                temp.push({src: object.src, title: object.title});
            });
            setCurrentLightboxIndex(imageID);
        } else {
            let counter = 0;

            images.filter(image => {return image.filter === filterKey}).forEach(object => {
                temp.push({src: object.src, title: object.title});

                if(imageID === object.id) {
                    setCurrentLightboxIndex(counter);
                }

                counter++;
            });
        }

        setCurrentImages(temp);
        setOpenLightbox(true);
    }

    useEffect(() => {
        AOS.refresh();
    });

    useEffect(() => {
        setIsotope(
            new Isotope(".portfolio-container", {
                itemSelector: ".portfolio-item",
                layoutMode: "masonry"
            })
        );
    }, []);

   useEffect(() => {
        if (isotope) {
            filterKey === "*"
                ? isotope.arrange({ filter: "*" })
                : isotope.arrange({ filter: `.${filterKey}` });
        }
    }, [isotope, filterKey]);

    return(
        <section id="images" className="portfolio">
            <Container className="section-title" data-aos="fade-up" data-aos-anchor-placement="top-center">
                <h2>Aktuelle Bilder</h2>
            </Container>

            <Container>
                <Row data-aos="fade-up" data-aos-anchor-placement="top-center">
                    <Col lg={12} className="d-flex justify-content-center">
                        <ul id="portfolio-flters">
                            <li onClick={() => setFilterKey("filter-event1")} className={filterKey === "filter-event1" ? "filter-active" : ""}>Barfußpfad</li>
                            <li onClick={() => setFilterKey("filter-event2")} className={filterKey === "filter-event2" ? "filter-active" : ""}>Markttag</li>
                            <li onClick={() => setFilterKey("filter-event3")} className={filterKey === "filter-event3" ? "filter-active" : ""}>Sonstiges</li>
                        </ul>
                    </Col>
                </Row>

                <Row className="portfolio-container" data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-delay="350">
                    {images.map((image) => (
                        <PortfolioCard
                            key={"portfolioImage_" + image.id}
                            sourcePath={image.src}
                            title={image.title}
                            description={image.description}
                            filterName={image.filter}
                            onClick={() => handlePortfolioButtonClick(image.id)}
                            isotopeObject={isotope}
                        />
                    ))}
                </Row>
            </Container>

            <Lightbox
                plugins={[Counter, Captions, Thumbnails]}
                open={openLightbox}
                animation={{swipe: 750}}
                controller={{closeOnPullDown: true, closeOnBackdropClick: true}}
                close={() => setOpenLightbox(false)}
                slides={currentImages}
                counter={{ container: { style: { top: "unset", bottom: 0 } } }}
                thumbnails={{
                    position: "bottom",
                    border: 0
                }}
                index={currentLightboxIndex}
            />
        </section>
    );
}