import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from "react-bootstrap/Nav";
import {NavLink} from "react-router-dom";

export default function TopBar() {
    return (
        <Navbar id="topbar" bg="primary" variant="dark" expand="lg" fixed="top">
            <Container>
                <Navbar.Brand as={NavLink} to="/">
                    <img
                        alt="Regenbogenland Logo"
                        src={require("../gfx/logoSmall.png")}
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                    />{' '}
                    <span className="d-none d-sm-inline-flex">Regenbogenland Westerkappeln</span>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link className="scrollLink" href="/#overview">Übersicht</Nav.Link>
                        <Nav.Link className="scrollLink" href="/#supporter">Akteure</Nav.Link>
                        <Nav.Link className="scrollLink" href="/#projects">Projekte</Nav.Link>
                        <Nav.Link className="scrollLink" href="/#images">Bilder</Nav.Link>
                        <Nav.Link className="scrollLink" href="/#supporterdetails">Trägerdetails</Nav.Link>
                        <Nav.Link className="scrollLink" href="/#calendar">Terminkalender</Nav.Link>
                        <Nav.Link className="scrollLink" href="/#contact">Kontakt</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}