import {Controller, useForm} from "react-hook-form";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, {useEffect, useState} from "react";
import {Spinner} from "react-bootstrap";

export const enum RowTypes {
    NEW = 0,
    EDITED = 1
}

export default function OverviewTableRow(props: any) {
    const {
        handleSubmit,
        control,
        reset,
        watch,
        formState: { isSubmitSuccessful, errors, dirtyFields, isSubmitted }
    } = useForm({mode: "onChange"});

    const [showSpinner, setShowSpinner] = useState(false);

    const resetValues = () => {
        reset({
            [props.request.id + "_titel"]: props.request.titel,
            [props.request.id + "_zeitslots"]: {
                "08-10": props.timeSlotsDisabled[props.request.id].includes("08-10"),
                "10-12": props.timeSlotsDisabled[props.request.id].includes("10-12"),
                "12-14": props.timeSlotsDisabled[props.request.id].includes("12-14"),
                "14-16": props.timeSlotsDisabled[props.request.id].includes("14-16"),
                "16-18": props.timeSlotsDisabled[props.request.id].includes("16-18"),
                "18-20": props.timeSlotsDisabled[props.request.id].includes("18-20")
            },
            [props.request.id + "_nachricht"]: "",
            [props.request.id + "_status"]: props.type === RowTypes.EDITED ? props.request.status : ""
        });
    };

    useEffect(() => {
        if (!isSubmitSuccessful) { return }

        resetValues();
    }, [isSubmitSuccessful, reset]);

    useEffect(() => {
        if(props.resetForm) {
            resetValues();
        }
    }, [props.resetForm, reset]);

    const timeslotOptions = [
        { id: "08-10", label: "08:00 - 10:00", value: 1},
        { id: "10-12", label: "10:00 - 12:00", value: 2},
        { id: "12-14", label: "12:00 - 14:00", value: 3},
        { id: "14-16", label: "14:00 - 16:00", value: 4},
        { id: "16-18", label: "16:00 - 18:00", value: 5},
        { id: "18-20", label: "18:00 - 20:00", value: 6}
    ];

    const statusValue = watch(props.request.id + "_status", props.request.status);

    const onSubmit = async (data: any) => {
        setShowSpinner(true);
        await props.onSubmit(data, props.request.id, props.type);
        setShowSpinner(false);
        await props.fetchFunction();
    };

    return(
        <Form id={"form_" + props.request.id} noValidate onSubmit={handleSubmit(onSubmit)}>
            <Row className="bg-secondary mt-4 text-decoration-underline py-3">
                <Col>
                    <h5>Anfrage-ID</h5>
                </Col>
                <Col>
                    <h5>Datum der Anfrage</h5>
                </Col>
                <Col>
                    <h5>Name</h5>
                </Col>
                <Col>
                    <h5>Bemerkung vom Nutzer</h5>
                </Col>
                <Col>
                    <h5>E-Mail</h5>
                </Col>
                <Col>
                    <h5>Telefonnummer</h5>
                </Col>
            </Row>

            <Row className="bg-secondary align-items-center">
                <Col>
                    {props.request.id}
                </Col>
                <Col>
                    {new Date(props.request.anfragedatum).toLocaleDateString("de-DE", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit"
                    })}
                </Col>
                <Col>
                    {props.request.vorname} {props.request.nachname}
                </Col>
                <Col>
                    "{props.request.bemerkung}"
                </Col>
                <Col>
                    {props.request.email}
                </Col>
                <Col>
                    {props.request.telefon}
                </Col>
            </Row>

            <Row className="bg-secondary text-decoration-underline" style={{paddingTop: "3%"}}>
                <Col>
                    <h5>Titel</h5>
                </Col>
                <Col>
                    <h5>Datum des Events</h5>
                </Col>
                <Col>
                    <h5>Zeitslots</h5>
                </Col>
                <Col>
                    <h5>Nachricht an den Nutzer (optional)</h5>
                </Col>
                <Col>
                    <h5>Status</h5>
                </Col>
                <Col></Col>
            </Row>

            <Row className="bg-secondary mb-3">
                <Col>
                    <Form.Group className="w-75" style={{margin: "0px auto"}}>
                        <Controller
                            name={props.request.id + "_titel"}
                            control={control}
                            rules={{
                                required: {value: true, message: "Bitte geben Sie einen Titel an"},
                                minLength: {value: 5, message: "Der Titel sollte länger sein"},
                                maxLength: {value: 20, message: "Der Titel sollte kürzer sein"},
                            }}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Form.Control
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    isInvalid={!!errors[props.request.id + "_titel"]}
                                    isValid={
                                        props.type === RowTypes.NEW ?
                                            dirtyFields[props.request.id + "_titel"] && !errors[props.request.id + "_titel"]
                                            :
                                            (dirtyFields[props.request.id + "_titel"] && !errors[props.request.id + "_titel"]) || (isSubmitted && !errors[props.request.id + "_titel"])}
                                    ref={ref}
                                    type="text"
                                    placeholder="Kalendertitel"
                                    className="text-center"
                                />
                            )}
                            defaultValue={props.request.titel}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors[props.request.id + "_titel"] && <p>{errors[props.request.id + "_titel"]?.message?.toString()}</p>}
                        </Form.Control.Feedback>
                        <Form.Text className="text-muted">
                            Dieser Titel wird <b>öffentlich</b> auf der Website zu sehen sei
                        </Form.Text>
                    </Form.Group>
                </Col>
                <Col>
                    <b><u>
                        {new Date(props.request.datum).toLocaleDateString("de-DE", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit"
                        })}
                    </u></b>
                </Col>
                <Col>
                    <Form.Group>
                        <Controller
                            name={props.request.id + "_zeitslots"}
                            control={control}
                            rules={{
                                validate: (zeitslots: any) =>
                                    Object.values(zeitslots).some((value) => value) ||
                                    "Wählen Sie bitte mindestens einen Zeitslot aus",
                            }}
                            render={({ field }) => (
                                <>
                                    {timeslotOptions.map((option) => (
                                        <div key={option.id}>
                                            <Form.Check
                                                type="checkbox"
                                                key={option.id}
                                                value={option.value}

                                                checked={field.value[option.id]}
                                                onChange={(e) =>
                                                    field.onChange({
                                                        ...field.value,
                                                        [option.id]: e.target.checked,
                                                    })
                                                }
                                                isInvalid={!!errors[props.request.id + "_zeitslots"]}
                                                isValid={(dirtyFields[props.request.id + "_zeitslots"] && !errors[props.request.id + "_zeitslots"]) || (isSubmitted && !errors[props.request.id + "_zeitslots"])}
                                                onBlur={field.onBlur}
                                                ref={field.ref}
                                                placeholder="Check"
                                                disabled={statusValue === "ABGELEHNT"}
                                                className="d-inline px-2"
                                            />
                                            <Form.Check.Label className="d-inline">
                                                {option.label}
                                            </Form.Check.Label>
                                        </div>
                                    ))}
                                </>
                            )}
                            defaultValue={{
                                "08-10": props.timeSlotsDisabled[props.request.id].includes("08-10"),
                                "10-12": props.timeSlotsDisabled[props.request.id].includes("10-12"),
                                "12-14": props.timeSlotsDisabled[props.request.id].includes("12-14"),
                                "14-16": props.timeSlotsDisabled[props.request.id].includes("14-16"),
                                "16-18": props.timeSlotsDisabled[props.request.id].includes("16-18"),
                                "18-20": props.timeSlotsDisabled[props.request.id].includes("18-20")
                            }}
                        />
                        <Form.Control.Feedback type="invalid" className="d-block">
                            {errors[props.request.id + "_zeitslots"] && <p>{errors[props.request.id + "_zeitslots"]?.message?.toString()}</p>}
                        </Form.Control.Feedback>
                        <Form.Text className="text-muted">
                            Aktuelle Zeitslots: <b>{props.request.zeitslots}</b>
                        </Form.Text>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Controller
                            name={props.request.id + "_nachricht"}
                            control={control}
                            rules={{
                                maxLength: {value: 500, message: "Die Nachricht ist zu lang"}
                            }}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Form.Control
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    isInvalid={!!errors[props.request.id + "_nachricht"]}
                                    isValid={dirtyFields[props.request.id + "_nachricht"] && !errors[props.request.id + "_nachricht"]}
                                    ref={ref}
                                    type="text"
                                    as="textarea"
                                    rows={4}
                                    style={{minHeight: "110px", maxHeight: "110px"}}
                                    placeholder={"Nachricht an " + props.request.vorname + " " + props.request.nachname}
                                />
                            )}
                            defaultValue=""
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors[props.request.id + "_nachricht"] && <p>{errors[props.request.id + "_nachricht"]?.message?.toString()}</p>}
                        </Form.Control.Feedback>
                        <Form.Text className="text-muted">
                            Diese Nachricht wird in die E-Mail an den Nutzer eingebettet
                        </Form.Text>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="w-75" style={{margin: "0px auto"}}>
                        <Controller
                            name={props.request.id + "_status"}
                            control={control}
                            rules={{
                                required: {value: true, message: "Bitte geben Sie einen anderen Status an"}
                            }}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Form.Select
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    isInvalid={!!errors[props.request.id + "_status"]}
                                    isValid={
                                        props.type === RowTypes.NEW ?
                                            dirtyFields[props.request.id + "_status"] && !errors[props.request.id + "_status"]
                                            :
                                            (dirtyFields[props.request.id + "_status"] && !errors[props.request.id + "_status"]) || (isSubmitted && !errors[props.request.id + "_status"])}

                                    ref={ref}
                                    placeholder="WARTEND"
                                    className="text-center"
                                >
                                    {props.type === RowTypes.NEW ? <option value="" disabled>WARTEND</option> : null}
                                    <option value="ZUGESAGT">ZUSAGEN</option>
                                    <option value="ABGELEHNT">ABSAGEN</option>
                                </Form.Select>
                            )}
                            defaultValue={props.type === RowTypes.EDITED ? props.request.status : ""}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors[props.request.id + "_status"] && <p>{errors[props.request.id + "_status"]?.message?.toString()}</p>}
                        </Form.Control.Feedback>
                        {props.type === RowTypes.EDITED &&
                            <Form.Text className="text-muted">
                                Aktueller Status: <b>{props.request.status}</b>
                            </Form.Text>
                        }
                    </Form.Group>
                </Col>
                <Col>
                    {showSpinner ? (
                            <Spinner animation="border" />
                        )
                        : (
                            <Button variant="primary" type="submit" className="text-white">
                                {props.type === RowTypes.NEW ? "Antwort abschicken" : "Korrektur vornehmen"}
                            </Button>
                        )}
                </Col>
            </Row>
        </Form>
    );
}