import Container from "react-bootstrap/Container";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/pagination";
import { Pagination, Autoplay } from "swiper";
import {Quote} from "react-bootstrap-icons";

export default function FeedbackPanel() {
    return (
        <section id="feedback" className="feedbacks bg-secondary">
            <Container className="section-title" data-aos="fade-up" data-aos-anchor-placement="top-center">
                <h2>Feedback</h2>
                <p>Das Regenbogenland freut sich jederzeit über Ihre Rückmeldung! Nutzen Sie gerne das
                    Kontaktformular und schreiben Sie uns eine Nachricht!</p>
            </Container>
            <Container data-aos="fade-up" data-aos-delay="150" data-aos-anchor-placement="top-center">
                <Swiper
                    className="feedback-slider"
                    modules={[Pagination, Autoplay]}
                    speed={600}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false
                    }}
                    pagination={{el: ".swiper-pagination", dynamicBullets: true, clickable: true}}
                    slidesPerView="auto"
                    spaceBetween={40}
                    breakpoints={{
                        320: {
                            slidesPerView: 1
                        },
                        768: {
                            slidesPerView: 2
                        },
                        1200: {
                            slidesPerView: 3
                        }
                    }}
                >
                    <SwiperSlide>
                        <div className="feedback-item">
                            <p className="shadow">
                                <Quote size={28} className="feedback-icon-left" />
                                Wie großartig, dass zum KiTa-Jubiläum das Regenbogenland mitgenutzt werden konnte. In
                                entspannter Atmosphäre konnten Klein und Groß einen fantastischen Tag genießen und die
                                Menge der Besucher hat sich gut verteilt. Danke an alle Helfer und Ehrenamtlichen, die
                                dieses Projekt möglich gemacht haben.
                                <Quote size={28} className="feedback-icon-right" />
                            </p>
                            <img src={require("../gfx/anonym.png")} className="feedback-img" alt="" />
                            <h3>Anonym</h3>
                            <h4>Besucher</h4>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="feedback-item">
                            <p className="shadow">
                                <Quote size={28} className="feedback-icon-left" />
                                Vielen Dank, dass wir unseren Kindergeburtstag bei euch feiern durften.
                                Die Kinder hatte alle sehr viel Spaß. <br/>
                                Super Ort mit vielen verschiedenen und spannenden Möglichkeiten. Sehr gepflegt und
                                aufgeräumt. <br/>

                                Ihr macht super Arbeit! Weiter so…
                                <Quote size={28} className="feedback-icon-right" />
                            </p>
                            <img src={require("../gfx/anonym.png")} className="feedback-img" alt="" />
                            <h3>Anonym</h3>
                            <h4>Besucher</h4>
                        </div>
                    </SwiperSlide>

                    <div className="swiper-pagination"></div>
                </Swiper>
            </Container>
        </section>
    );
}
