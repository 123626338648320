import React, {useEffect} from "react";
import "react-toastify/dist/ReactToastify.css";
import AOS from "aos";
import {useLocation} from "react-router-dom";
import ProjectOverview from "./ProjectOverview";
import CurrentProjects from "./CurrentProjects";
import ImagePortfolio from "./ImagePortfolio";
import ReservationCalendar from "./ReservationCalendar";
import ContactForm from "./ContactForm";
import MainLayout from "./MainLayout";
import SupporterOverview from "./SupporterOverview";
import FeedbackPanel from "./FeedbackPanel";
import SupporterDetails from "./SupporterDetails";

export default function MainPage(props: any) {
    let location = useLocation();

    useEffect(() => {
        AOS.refresh();
    });

    const navbarLinksActive = () => {
        let navbarLinks: any = document.querySelectorAll("#topbar .nav-link");
        let position: number = window.scrollY + 520;

        navbarLinks.forEach((navbarLink: HTMLAnchorElement) => {
            if(!navbarLink.hash) return;
            let section: any = document.querySelector(navbarLink.hash);
            if(!section) return;
            if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
                navbarLink.classList.add("active");
            } else {
                navbarLink.classList.remove("active");
            }
        });
    };

    useEffect(() => {
        if(!!location.hash) {
            document.getElementById(location.hash.slice(1, location.hash.length))?.scrollIntoView({ behavior: "smooth"});
        }

        let scrollElements: NodeListOf<HTMLAnchorElement> = document.querySelectorAll(".scrollLink");
        scrollElements.forEach((element: HTMLAnchorElement) => {
            element.addEventListener("click", (e) => {
                e.preventDefault();

                if(!!element.hash) {
                    document.getElementById(element.hash.slice(1, element.hash.length))?.scrollIntoView({ behavior: "smooth"});
                }
            });
        });

        navbarLinksActive();

        window.addEventListener("load", navbarLinksActive);
        document.addEventListener("scroll", navbarLinksActive);
        return () => {
            window.removeEventListener("load", navbarLinksActive);
            document.removeEventListener("scroll", navbarLinksActive);

            let navbarLinks: any = document.querySelectorAll("#topbar .nav-link");
            navbarLinks.forEach((navbarLink: HTMLAnchorElement) => {
                navbarLink.classList.remove("active");
            });
        }
    }, []);

    return(
        <MainLayout>
            <ProjectOverview />
            <SupporterOverview />
            <CurrentProjects />
            <ImagePortfolio />
            <SupporterDetails />
            <ReservationCalendar toastfunction={props.toastfunction} />
            <FeedbackPanel />
            <ContactForm toastfunction={props.toastfunction} />
        </MainLayout>
    );
}