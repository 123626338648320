import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MainLayout from "./MainLayout";

export default function Imprint() {
    return(
        <MainLayout>
            <Container className="w-75 mt-5 p-3" data-aos="fade-up">
                <Row className="mt-3">
                    <Col>
                        <h3>Impressum</h3>
                        <p>Ev. Familienzentrum Am Kapellenweg<br/>
                            Am Kapellenweg 87<br/>
                            49492 Westerkappeln<br/><br/>
                            Telefon: 05404 / 4130<br/>
                            E-Mail: <a href="mailto:kiga-kapellenweg@kk-te.de">kiga-kapellenweg@kk-te.de</a><br/>
                            Einrichtungsleitung: Anja Peters<br/><br/>
                            Träger der Einrichtung:<br/>
                            Kindergartenverbund im Ev. Kirchenkreis Tecklenburg<br/>
                            Schulstraße 71<br/>
                            49525 Lengerich<br/><br/>
                            Verantwortlich:<br/>
                            Kindergartenverbund im Ev. Kirchenkreis Tecklenburg<br/>
                            Geschäftsführung: Herr Ralf Evers<br/>
                            Schulstraße 11a<br/>
                            49477 Ibbenbüren<br/><br/>
                            Telefon: (05451) 5499 / 227<br/>
                            E-Mail: <a href="mailto:ralf.evers@ekvw.de">ralf.evers@ekvw.de</a><br/>
                            Homepage: <a href="https://www.kirchenkreis-tecklenburg.de/service/kindertageseinrichtungen">www.kirchenkreis-tecklenburg.de</a></p>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <h3>Haftungsausschluss</h3>

                        <h5>Haftung für Inhalte</h5>
                        <p>Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
                            allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch
                            nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach
                            Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur
                            Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben
                            hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis
                            einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
                            Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>

                        <h5>Haftung für Links</h5>
                        <p>Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss
                            haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die
                            Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
                            verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche
                            Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
                            erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
                            Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen
                            werden wir derartige Links umgehend entfernen.</p>

                        <h5>Urheberrecht</h5>
                        <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem
                            deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
                            Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
                            jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten,
                            nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber
                            erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter
                            als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam
                            werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
                            werden wir derartige Inhalte umgehend entfernen.</p>

                        <p>Quelle: <a href="https://www.e-recht24.de">e-recht24.de</a></p>
                    </Col>
                </Row>
            </Container>
        </MainLayout>
    );
}