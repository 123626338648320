import {useEffect, useState} from "react";
import Col from "react-bootstrap/Col";
import {AspectRatio} from "react-bootstrap-icons";
import Placeholder from "react-bootstrap/Placeholder";
import Card from "react-bootstrap/Card";

export default function PortfolioCard(props: any) {
    const [sourceLoaded, setSourceLoaded] = useState(false);

    useEffect(() => {
        props.isotopeObject?.layout();
    });

    return (
        <Col lg={2} md={3} className={`portfolio-item ${ props.filterName }`}>
            <div className={sourceLoaded ? "portfolio-wrap" : undefined}>
                <img
                    src={props.sourcePath}
                    className="img-fluid"
                    alt=""
                    onLoad={() => setSourceLoaded(true)}
                    style={sourceLoaded ? {} : { display: "none" }}
                />

                {sourceLoaded ?
                    <div className="portfolio-info">
                        <h4>{props.title}</h4>
                        <p>{props.description}</p>
                        <div className="portfolio-links">
                            <AspectRatio size={23} onClick={props.onClick} />
                        </div>
                    </div>
                    : (
                    <Card style={{marginBottom: "25%"}}>
                        <Card.Body>
                            <Placeholder as={Card.Title} animation="glow">
                                <Placeholder xs={4} />
                            </Placeholder>
                            <Placeholder as={Card.Text} animation="glow">
                                <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                                <Placeholder xs={6} /> <Placeholder xs={2} />
                            </Placeholder>
                        </Card.Body>
                    </Card>
                    )
                }
            </div>
        </Col>
    );
}