import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OverviewCard from "../components/OverviewCard";
import {useEffect} from "react";
import AOS from "aos";

export default function ProjectOverview() {
    useEffect(() => {
        AOS.refresh();
    });

    return (
        <section id="overview">
            <Container fluid>
                <Container className="text-center mt-1 p-3 text-primary" data-aos="fade-up" data-aos-delay="150">
                    <img
                        id="mainLogoReg"
                        alt="Regenbogenland Logo"
                        src={require("../gfx/logo.png")}
                        width="180"
                        height="180"
                        className="d-inline-block align-top"
                    />{' '}
                    <h1>Herzlich Willkommen</h1>
                    <h2>im Regenbogenland Westerkappeln</h2>
                </Container>
                <Container>
                    <Row xs={1} md={2} lg={3} className="g-4 justify-content-md-center" data-aos="fade-up" data-aos-delay="650">
                        <Col>
                            <OverviewCard
                                sourcePath={require("../gfx/bauernGarten.png")}
                                cardTitle={"Bauerngarten"}
                                cardText={""}
                                refTo="#bauerngarten"
                            />
                        </Col>
                        <Col>
                            <OverviewCard
                                sourcePath={require("../gfx/bauWagen.png")}
                                cardTitle={"Bauwagen"}
                                cardText={""}
                                refTo="#bauwagen"
                            />
                        </Col>
                        <Col>
                            <OverviewCard
                                sourcePath={require("../gfx/kohlundOkra.jpeg")}
                                cardTitle={"Okra & Kohl"}
                                cardText={""}
                                refTo="#okraundkohl"
                            />
                        </Col>
                    </Row>

                    <Row className="my-4 justify-content-md-center" data-aos="fade-up" data-aos-delay="850">
                        <Col xs={12} md={10} lg={7}>
                            <Card bg="body" border="0" className="shadow">
                                <video
                                    src={require("../gfx/imageFilm.mp4")}
                                    loop
                                    autoPlay={true}
                                    muted={true}
                                    controls={true} />
                            </Card>
                        </Col>
                    </Row>

                    <Row xs={1} md={2} lg={3} className="g-4 justify-content-md-center" data-aos="fade-up" data-aos-delay="1050">
                        <Col>
                            <OverviewCard
                                sourcePath={require("../gfx/barfußPark.png")}
                                cardTitle={"Barfußpark (im Aufbau)"}
                                cardText={""}
                                refTo="#barfußpark"
                            />
                        </Col>
                        <Col>
                            <OverviewCard
                                sourcePath={require("../gfx/biologischeVielfalt.png")}
                                cardTitle={"Biologische Vielfalt"}
                                cardText={""}
                                refTo="#biologischevielfalt"
                            />
                        </Col>
                        <Col>
                            <OverviewCard
                                sourcePath={require("../gfx/weitereProjekte.png")}
                                cardTitle={"Impressionen"}
                                cardText={"Neben den geannten Projekten gibt es noch mehr zu entdecken"}
                                refTo="#images"
                            />
                        </Col>
                    </Row>
                </Container>
            </Container>
        </section>
    );
}