import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import {TabContainer, TabContent, TabPane} from "react-bootstrap";
import {CashStack, PersonFillAdd, StarFill} from "react-bootstrap-icons";
import AOS from "aos";
import {useEffect} from "react";

export default function SupporterDetails(props: any) {
    useEffect(() => {
        AOS.refresh();
    });

    return(
        <section id="supporterdetails" className="supporterdetails bg-secondary">
            <Container className="section-title" data-aos="fade-up" data-aos-anchor-placement="top-center">
                <h2>Unsere Akteure im Detail</h2>
            </Container>

            <Container data-aos="fade-up" data-aos-delay="150" data-aos-anchor-placement="top-center">
                <TabContainer id="supporterDetailsTab" defaultActiveKey="efak">
                    <Row>
                        <Nav variant="tabs" className="d-flex">
                            <Col xs={6} sm={3} xl>
                                <Nav.Item className="h-100 p-2">
                                    <Nav.Link eventKey="efak" className="shadow">
                                        <img
                                            alt="FZ Kapellenweg Logo"
                                            src={require("../gfx/supporterLogos/fzKapellenweg.png")}
                                            width="85px"
                                        />
                                        <p>Ev. Familienzentrum am Kapellenweg</p>
                                    </Nav.Link>
                                </Nav.Item>
                            </Col>
                            <Col xs={6} sm={3} xl>
                                <Nav.Item className="h-100 p-2">
                                    <Nav.Link eventKey="gelowe" className="shadow">
                                        <img
                                            alt="GeLoWe Logo"
                                            src={require("../gfx/supporterLogos/gelowe.png")}
                                            width="65px"
                                        />
                                        <p>Gesamtschule Lotte-Westerkappeln</p>
                                    </Nav.Link>
                                </Nav.Item>
                            </Col>
                            <Col xs={6} sm={3} xl>
                                <Nav.Item className="h-100 p-2">
                                    <Nav.Link eventKey="wespe" className="shadow">
                                        <img
                                            alt="Wespe Logo"
                                            src={require("../gfx/supporterLogos/wespe.png")}
                                            width="105px"
                                        />
                                        <p>WeSpE e.V.</p>
                                    </Nav.Link>
                                </Nav.Item>
                            </Col>
                            <Col xs={6} sm={3} xl>
                                <Nav.Item className="h-100 p-2">
                                    <Nav.Link eventKey="evkw" className="shadow">
                                        <img
                                            alt="Kirchgemeinde We Logo"
                                            src={require("../gfx/supporterLogos/kgWE.png")}
                                            width="70px"
                                        />
                                        <p>Ev. Kirchengemeinde Westerkappeln</p>
                                    </Nav.Link>
                                </Nav.Item>
                            </Col>
                            <Col xs={6} sm={4} xl>
                                <Nav.Item className="h-100 p-2">
                                    <Nav.Link eventKey="förderer" className="shadow">
                                        <StarFill size={30} style={{marginBottom: "10px"}}/>
                                        <p>Förderer</p>
                                    </Nav.Link>
                                </Nav.Item>
                            </Col>
                            <Col xs={6} sm={4} xl>
                                <Nav.Item className="h-100 p-2">
                                    <Nav.Link eventKey="unterstützer" className="shadow">
                                        <PersonFillAdd size={30} style={{marginBottom: "10px"}}/>
                                        <p>Unterstützer</p>
                                    </Nav.Link>
                                </Nav.Item>
                            </Col>
                            <Col xs={6} sm={4} xl>
                                <Nav.Item className="h-100 p-2">
                                    <Nav.Link eventKey="spender" className="shadow">
                                        <CashStack size={30} style={{marginBottom: "10px"}}/>
                                        <p>Spender</p>
                                    </Nav.Link>
                                </Nav.Item>
                            </Col>
                        </Nav>
                    </Row>
                    <TabContent>
                        <TabPane eventKey="efak" className="bg-white p-5 shadow">
                            <Row className="gy-4 d-flex justify-content-center align-items-center">
                                <Col lg={8} className="order-2 order-lg-1">
                                    <h3>Evangelisches Familienzentrum am Kapellenweg</h3>
                                    <p>
                                        Das Ev. Familienzentrum Am Kapellenweg ist eine Kindertagesstätte mit derzeit
                                        97 Kindern im Alter  von 0-6 Jahren. Das Regenbogenland ist ca 200 Meter von der
                                        Kita entfernt.
                                    </p>
                                    <p>
                                        Als die Ev. Kirchengemeinde Westerkappeln der Kita das Grundstück für
                                        Projektarbeit angeboten hat, boten sich dem Team neue, ungeahnte Möglichkeiten.
                                        Vom Bauerngarten bis zur Tierhaltung war nun vieles denkbar und möglich.
                                    </p>
                                    <p>
                                        Hoch motiviert entwickelte das KiTa-Team eine Konzeptidee und machte sich auf
                                        die Suche nach Kooperationspartnern. So entstanden die ersten Kontakte zur
                                        GeLoWe, der WeSpE e.V. und dem Hof Bergesch. Durch Workshops mit Schülern und
                                        Kitakindern, die vom Planungsbüro Brand begleitet wurden, entstand die erste
                                        Zeichnung, die die Visionen der Beteiligten aufs Papier brachte...
                                    </p>
                                    <p>
                                        Die Homepage des Evangelischen Familienzentrums am Kapellenweg finden Sie <a href="https://www.familienzentrum-kapellenweg.de/">hier</a>.
                                    </p>
                                </Col>
                                <Col lg={4} className="order-2 order-lg-1 text-center">
                                    <a href="https://www.familienzentrum-kapellenweg.de/">
                                        <img
                                            src={require("../gfx/supporterLogos/fzKapellenwegGroß.png")}
                                            alt="FZ Kapellenweg Logo"
                                            className="img-fluid"
                                        />
                                    </a>
                                </Col>
                            </Row>
                        </TabPane>

                        <TabPane eventKey="gelowe" className="bg-white p-5 shadow">
                            <Row className="gy-4 d-flex justify-content-center align-items-center">
                                <Col lg={8} className="order-2 order-lg-1">
                                    <h3>Gesamtschule Lotte-Westerkappeln</h3>
                                    <p>
                                        Die Gesamtschule Lotte-Westerkappeln, kurz GeLoWe, ist eine Gesamtschule mit
                                        Standorten in Lotte und Westerkappeln. Mittlerweile besuchen 1016 Schüler*Innen
                                        die Schule.
                                    </p>
                                    <p>
                                        Als das Familienzentrum Am Kapellenweg den Kontakt zu der Schule suchte, um eine
                                        Kooperation anzubahnen, mussten die Schulleitung und Lehrer*Innen nicht lange
                                        überzeugt werden. Durch Workshops mit Schüler*Innen und Kitakindern,die vom
                                        Planungsbüro Brand begleitet wurden, entstand die erste Zeichnung, die die
                                        Visionen aller Beteiligten aufs Papier brachte.
                                    </p>
                                    <p>
                                        Bald darauf rückten Schüler*Innen und Lehrer*Innen im Regenbogenland an, um
                                        erste Projektideen wie die Pflanzung von Obstgehölzen, das Anlegen einer
                                        Blumenwiese, den Bau eines Schafstalls, das Errichten  einer Dirtbahn u.v.m.
                                        gemeinsam mit vielen Ehrenamtlichen, Kitakindern und Erzieher*Innen der Kita
                                        umzusetzen. Bis heute entstehen immer wieder neue Ideen, die Schüler*Innen im
                                        Projektunterricht mit Lehrer*Innen umsetzen.
                                    </p>
                                    <p>
                                        Die Homepage der Gesamtschule Lotte-Westerkappeln finden Sie <a href="https://gesamtschule-lowe.de/">hier</a>.
                                    </p>
                                </Col>
                                <Col lg={4} className="order-2 order-lg-1 text-center">
                                    <a href="https://gesamtschule-lowe.de/">
                                        <img
                                            src={require("../gfx/supporterLogos/geloweGroß.png")}
                                            alt="GeLoWe Logo"
                                            className="img-fluid"
                                        />
                                    </a>
                                </Col>
                            </Row>
                        </TabPane>

                        <TabPane eventKey="wespe" className="bg-white p-5 shadow">
                            <Row className="gy-4 d-flex justify-content-center align-items-center">
                                <Col lg={8} className="order-2 order-lg-1">
                                    <h3>Westerkappelner Elterninitative für Spielen und Erleben e.V. (WeSpE)</h3>
                                    <p>
                                        Die WeSpE e.V. wurde im Jahr 1992 gegründet. Unter dem Dach der WeSpE sammeln
                                        sich der Förderverein der Grundschule Am Bullerdiek und der GeLoWe,
                                        das Kinderbüro, das Haus der WeSpE sowie das Büro für Bildung und Teilhabe,
                                        kurz BuT.
                                    </p>
                                    <p>
                                        Gerne war die WeSpE bereit, die Trägerschaft für das Regenbogenland zu
                                        übernehmen. Mit dieser weiteren Kooperation sind Angebote wie das Projekt Okra
                                        und Kohl oder der Bau eines Tinyhauses ins Regenbogenland eingezogen. Zusätzlich
                                        bereichern Aktionen im Rahmen des Ferienspaßes oder des Wespenstichs die
                                        Angebotspalette im Regenbogenland.
                                    </p>
                                    <p>
                                        Die Homepage der WeSpE e.V. finden Sie <a href="https://wespennetzwerk.jimdofree.com/">hier</a>.
                                    </p>
                                </Col>
                                <Col lg={4} className="order-2 order-lg-1 text-center">
                                    <a href="https://wespennetzwerk.jimdofree.com/">
                                        <img
                                            src={require("../gfx/supporterLogos/wespeGroß.png")}
                                            alt="WeSpE Logo"
                                            className="img-fluid"
                                        />
                                    </a>
                                </Col>
                            </Row>
                        </TabPane>

                        <TabPane eventKey="evkw" className="bg-white p-5 shadow">
                            <Row className="gy-4 d-flex justify-content-center align-items-center">
                                <Col lg={8} className="order-2 order-lg-1">
                                    <h3>Evangelische Kirchengemeinde Westerkappeln</h3>
                                    <p>
                                        Die Ev. Kirchengemeinde Westerkappeln ist als evangelische Institution eng mit
                                        dem Ev. Familienzentrum Am Kapellenweg verwoben. Nachdem der Pachtvertrag mit
                                        der anliegenden Gärtnerei auslief, ist die Kirchengemeinde auf das
                                        Familienzentrum Am Kapellenweg zugekommen, um das Grundstück zur Nutzung
                                        anzubieten.
                                    </p>
                                    <p>
                                        Hoch motiviert machte das Team der Kita sich an die Arbeit, um einen
                                        Konzeptentwurf vorzubereiten. Das Presbyterium der Kirchengemeinde stimmte dem
                                        Entwurf einstimmig zu und unterstützt das Projekt durch einen kostenfreien
                                        Pachtvertrag.
                                    </p>
                                    <p>
                                        Die Homepage der Evangelischen Kirchengemeinde Westerkappeln finden Sie <a href="https://www.facebook.com/KirchengemeindeWesterkappeln/">hier</a>.
                                    </p>
                                </Col>
                                <Col lg={4} className="order-2 order-lg-1 text-center">
                                    <a href="https://www.facebook.com/KirchengemeindeWesterkappeln/">
                                        <img
                                            src={require("../gfx/supporterLogos/kgWeGroß.png")}
                                            alt="Kirchengemeinde WK Logo"
                                            className="img-fluid w-50"
                                        />
                                    </a>
                                </Col>
                            </Row>
                        </TabPane>

                        <TabPane eventKey="förderer" className="bg-white p-5 shadow">
                            <Row className="gy-4 d-flex justify-content-center align-items-center">
                                <Col className="order-2 order-lg-1">
                                    <h3>Förderer</h3>
                                    <ul className="list-group">
                                        <div className="list-group-item border-0">
                                            <span className="d-inline-flex align-items-center justify-content-center text-white rounded-circle m-1 me-3" style={{backgroundColor: "#73A24E", width: "50px", height: "50px"}}>
                                                <StarFill />
                                            </span>
                                            <i style={{fontSize: "107%"}}>Leader Kleinprojekte</i>
                                        </div>
                                        <div className="list-group-item border-0">
                                            <span className="d-inline-flex align-items-center justify-content-center text-white rounded-circle m-1 me-3" style={{backgroundColor: "#73A24E", width: "50px", height: "50px"}}>
                                                <StarFill />
                                            </span>
                                            <i style={{fontSize: "107%"}}>Bohnenkampstiftung</i>
                                        </div>
                                        <div className="list-group-item border-0">
                                            <span className="d-inline-flex align-items-center justify-content-center text-white rounded-circle m-1 me-3" style={{backgroundColor: "#73A24E", width: "50px", height: "50px"}}>
                                                <StarFill />
                                            </span>
                                            <i style={{fontSize: "107%"}}>Kreis Steinfurt</i>
                                        </div>
                                        <div className="list-group-item border-0">
                                            <span className="d-inline-flex align-items-center justify-content-center text-white rounded-circle m-1 me-3" style={{backgroundColor: "#73A24E", width: "50px", height: "50px"}}>
                                                <StarFill />
                                            </span>
                                            <i style={{fontSize: "107%"}}>Leader</i>
                                        </div>
                                    </ul>
                                </Col>
                            </Row>
                        </TabPane>

                        <TabPane eventKey="unterstützer" className="bg-white p-5 shadow">
                            <Row className="gy-4 d-flex justify-content-center align-items-center">
                                <Col className="order-2 order-lg-1">
                                    <h3>Unterstützer</h3>
                                    <ul className="list-group">
                                        <div className="list-group-item border-0">
                                            <span className="d-inline-flex align-items-center justify-content-center text-white rounded-circle m-1 me-3" style={{backgroundColor: "#73A24E", width: "50px", height: "50px"}}>
                                                <PersonFillAdd />
                                            </span>
                                            <i style={{fontSize: "107%"}}>Die Häckselprofis</i>
                                        </div>
                                        <div className="list-group-item border-0">
                                            <span className="d-inline-flex align-items-center justify-content-center text-white rounded-circle m-1 me-3" style={{backgroundColor: "#73A24E", width: "50px", height: "50px"}}>
                                                <PersonFillAdd />
                                            </span>
                                            <i style={{fontSize: "107%"}}>Hof Bergesch</i>
                                        </div>
                                        <div className="list-group-item border-0">
                                            <span className="d-inline-flex align-items-center justify-content-center text-white rounded-circle m-1 me-3" style={{backgroundColor: "#73A24E", width: "50px", height: "50px"}}>
                                                <PersonFillAdd />
                                            </span>
                                            <i style={{fontSize: "107%"}}>Ev. Familienzentrum Anne Frank</i>
                                        </div>
                                    </ul>
                                </Col>
                            </Row>
                        </TabPane>

                        <TabPane eventKey="spender" className="bg-white p-5 shadow">
                            <Row className="gy-4 d-flex justify-content-center align-items-center">
                                <Col className="order-2 order-lg-1">
                                    <h3>Spender</h3>
                                    <ul className="list-group">
                                        <div className="list-group-item border-0">
                                            <span className="d-inline-flex align-items-center justify-content-center text-white rounded-circle m-1 me-3" style={{backgroundColor: "#73A24E", width: "50px", height: "50px"}}>
                                                <CashStack />
                                            </span>
                                            <i style={{fontSize: "107%"}}>GSW Westerkappeln</i>
                                        </div>
                                        <div className="list-group-item border-0">
                                            <span className="d-inline-flex align-items-center justify-content-center text-white rounded-circle m-1 me-3" style={{backgroundColor: "#73A24E", width: "50px", height: "50px"}}>
                                                <CashStack />
                                            </span>
                                            <i style={{fontSize: "107%"}}>Martin‘s das feine Genießen</i>
                                        </div>
                                        <div className="list-group-item border-0">
                                            <span className="d-inline-flex align-items-center justify-content-center text-white rounded-circle m-1 me-3" style={{backgroundColor: "#73A24E", width: "50px", height: "50px"}}>
                                                <CashStack />
                                            </span>
                                            <i style={{fontSize: "107%"}}>SPD Ortsverein Westerkappeln</i>
                                        </div>
                                        <div className="list-group-item border-0">
                                            <span className="d-inline-flex align-items-center justify-content-center text-white rounded-circle m-1 me-3" style={{backgroundColor: "#73A24E", width: "50px", height: "50px"}}>
                                                <CashStack />
                                            </span>
                                            <i style={{fontSize: "107%"}}>Landfrauen Westerkappeln</i>
                                        </div>
                                    </ul>
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>
                </TabContainer>
            </Container>
        </section>
    );
}