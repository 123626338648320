import TopBar from "./TopBar";
import BottomBar from "./BottomBar";

export default function MainLayout(props: any) {
    return(
        <>
            <TopBar />
            {props.children}
            <BottomBar />
        </>
    );
}