import React, {useEffect} from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import { Routes, Route } from "react-router-dom";
import MainPage from "./modules/MainPage";
import Imprint from "./modules/Imprint";
import DataPrivacy from "./modules/DataPrivacy";
import Administration from "./modules/Administration";
import {toast} from "react-toastify";

function App() {
    function showToast(containerID:string, type: number, message: string) {
        if(type === 1) {
            toast.success(message, {
                containerId: containerID,
                theme: "colored"
            });
        }
        if(type === 2) {
            toast.error(message, {
                containerId: containerID,
                theme: "colored"
            });
        }
        if(type === 3) {
            toast.info(message, {
                containerId: containerID,
                theme: "colored"
            });
        }
    }

    useEffect(() => {
        AOS.init({duration: 900, once: true});
    }, []);

    return (
        <Routes>
            <Route path="/" element={<MainPage toastfunction={showToast} />} />
            <Route path="impressum" element={<Imprint />} />
            <Route path="datenschutz" element={<DataPrivacy />} />
            <Route path="verwaltung" element={<Administration toastfunction={showToast} />} />
            <Route path="*" element={<MainPage toastfunction={showToast} />} />
        </Routes>
  );
}

export default App;