import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import React, {useEffect} from "react";
import {GeoAltFill, GlobeAmericas, Mailbox, TelephoneFill} from "react-bootstrap-icons";
import {Controller, useForm} from "react-hook-form";
import Form from "react-bootstrap/Form";
import {FloatingLabel} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {ToastContainer} from "react-toastify";
import AOS from "aos";

export default function ContactForm(props: any) {
    const {
        handleSubmit,
        control,
        reset,
        formState: { isSubmitSuccessful, errors, dirtyFields }} = useForm({mode: "onChange"});

    const onSubmit = async (data: any) => {
        let body = {
            vorname: data.vorname,
            nachname: data.nachname,
            email: data.email,
            betreff: data.betreff,
            bemerkung: data.bemerkung
        }

        fetch("https://regenbogenland-westerkappeln.de/backend/contact.php", {
            method: "POST",
            body: JSON.stringify(body)
        })
            .then((response) => response.json())
            .then((data) => {
                if(data.erfolg === "Ok") {
                    props.toastfunction("CF", 1, "Ihre Nachricht wurde erfolgreich verschickt");
                } else {
                    props.toastfunction("CF", 2, "Beim Versenden Ihrer Nachricht ist ein Fehler aufgetreten. Grund: " + data.fehler);
                }
            }).catch(error => {
                props.toastfunction("CF", 2, "Beim Versenden Ihrer Nachricht ist ein Fehler aufgetreten");
        });
    }

    useEffect(() => {
        AOS.refresh();
    });

    useEffect(() => {
        if (!isSubmitSuccessful) { return }

        reset({
            vorname: "",
            nachname: "",
            email: "",
            betreff: "",
            bemerkung: ""
        });
    }, [isSubmitSuccessful, reset]);

    return(
        <section id="contact">
            <Container className="mt-5 mb-5" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
                <Row className="justify-content-center">
                    <Col lg={10} md={12}>
                        <div className="w-100 shadow">
                            <Row className="g-0">
                                <Col md={7} className="d-flex align-items-stretch">
                                    <div className="contact-wrap w-100 p-md-5 p-4">
                                        <h3 className="mb-4">Schreiben Sie uns eine Nachricht</h3>
                                        <Form id="contactForm" noValidate onSubmit={handleSubmit(onSubmit)}>
                                            <Row>
                                                <Col md={6}>
                                                    <FloatingLabel label="Vorname" className="mb-3">
                                                        <Controller
                                                            name="vorname"
                                                            control={control}
                                                            rules={{
                                                                required: {value: true, message: "Bitte geben Sie einen Vornamen an"},
                                                                maxLength: {value: 30, message: "Ihr Vorname darf maximal 30 Zeichen haben"},
                                                            }}
                                                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                                                <Form.Control
                                                                    onChange={onChange}
                                                                    onBlur={onBlur}
                                                                    value={value}
                                                                    isInvalid={!!errors.vorname}
                                                                    isValid={dirtyFields.vorname && !errors.vorname}
                                                                    ref={ref}
                                                                    type="text"
                                                                    placeholder="Max"
                                                                />
                                                            )}
                                                            defaultValue=""
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.vorname && <p>{errors.vorname?.message?.toString()}</p>}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                                <Col md={6}>
                                                    <FloatingLabel label="Nachname" className="mb-3">
                                                        <Controller
                                                            name="nachname"
                                                            control={control}
                                                            rules={{
                                                                required: {value: true, message: "Bitte geben Sie einen Nachnamen an"},
                                                                maxLength: {value: 30, message: "Ihr Nachname darf maximal 30 Zeichen haben"},
                                                            }}
                                                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                                                <Form.Control
                                                                    onChange={onChange}
                                                                    onBlur={onBlur}
                                                                    value={value}
                                                                    isInvalid={!!errors.nachname}
                                                                    isValid={dirtyFields.nachname && !errors.nachname}
                                                                    ref={ref}
                                                                    type="text"
                                                                    placeholder="Mustermann"
                                                                />
                                                            )}
                                                            defaultValue=""
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.nachname && <p>{errors.nachname?.message?.toString()}</p>}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                                <Col md={12}>
                                                    <FloatingLabel label="E-Mail" className="mb-3">
                                                        <Controller
                                                            name="email"
                                                            control={control}
                                                            rules={{
                                                                required: {value: true, message: "Bitte geben Sie eine E-Mail-Adresse an"},
                                                                pattern: {value: /\S+@\S+\.\S+/, message: "Überprüfen Sie das Format Ihrer E-Mail-Adresse"},
                                                                maxLength: {value: 50, message: "Ihre E-Mail-Adresse darf maximal 50 Zeichen haben"},
                                                            }}
                                                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                                                <Form.Control
                                                                    onChange={onChange}
                                                                    onBlur={onBlur}
                                                                    value={value}
                                                                    isInvalid={!!errors.email}
                                                                    isValid={dirtyFields.email && !errors.email}
                                                                    ref={ref}
                                                                    type="email"
                                                                    placeholder="nutzer@beispiel.de"
                                                                />
                                                            )}
                                                            defaultValue=""
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.email && <p>{errors.email?.message?.toString()}</p>}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                                <Col md={12}>
                                                    <FloatingLabel label="Betreff" className="mb-3">
                                                        <Controller
                                                            name="betreff"
                                                            control={control}
                                                            rules={{
                                                                required: {value: true, message: "Bitte geben Sie einen Betreff an"},
                                                                maxLength: {value: 35, message: "Ihr Betreff darf maximal 35 Zeichen haben"},
                                                            }}
                                                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                                                <Form.Control
                                                                    onChange={onChange}
                                                                    onBlur={onBlur}
                                                                    value={value}
                                                                    isInvalid={!!errors.betreff}
                                                                    isValid={dirtyFields.betreff && !errors.betreff}
                                                                    ref={ref}
                                                                    type="text"
                                                                    placeholder="Betreff"
                                                                />
                                                            )}
                                                            defaultValue=""
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.betreff && <p>{errors.betreff?.message?.toString()}</p>}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                                <Col md={12}>
                                                    <FloatingLabel label="Worum gehts?" className="mb-3">
                                                        <Controller
                                                            name="bemerkung"
                                                            control={control}
                                                            rules={{
                                                                required: {value: true, message: "Bitte beschreiben Sie kurz Ihr Anliegen"},
                                                                minLength: {value: 20, message: "Ihre Nachricht sollte etwas länger sein"},
                                                                maxLength: {value: 380, message: "Ihre Nachricht ist zu lang"}
                                                            }}
                                                            render={({ field: { onChange, onBlur, value, ref }}) => (
                                                                <Form.Control
                                                                    onChange={onChange}
                                                                    onBlur={onBlur}
                                                                    value={value}
                                                                    isInvalid={!!errors.bemerkung}
                                                                    isValid={dirtyFields.bemerkung && !errors.bemerkung}
                                                                    ref={ref}
                                                                    type="text"
                                                                    as="textarea"
                                                                    rows={4}
                                                                    style={{minHeight: "140px", maxHeight: "150px"}}
                                                                    placeholder="Worum gehts?"
                                                                />
                                                            )}
                                                            defaultValue=""
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.bemerkung && <p>{errors.bemerkung?.message?.toString()}</p>}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                                <Col md={12}>
                                                    <Button variant="primary" style={{color: "#FFFFFF"}} type="submit">
                                                        Nachricht abschicken
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </Col>
                                <Col md={5} className="d-flex align-items-stretch">
                                    <div className="info-wrap bg-primary w-100 p-lg-5 p-4">
                                        <h3 className="mb-4 mt-md-4">Kontakt</h3>
                                        <div className="dbox w-100 d-flex align-items-start">
                                            <div className="icon d-flex align-items-center justify-content-center">
                                                <GeoAltFill size={20} />
                                            </div>
                                            <div className="text ps-3">
                                                <p><span>Adresse:</span> Am Kapellenweg 87, 49492 Westerkappeln</p>
                                            </div>
                                        </div>
                                        <div className="dbox w-100 d-flex align-items-center">
                                            <div className="icon d-flex align-items-center justify-content-center">
                                                <TelephoneFill size={20} />
                                            </div>
                                            <div className="text ps-3">
                                                <p><span>Telefon:</span> <a href="tel:+4954044130">05404 / 4130</a></p>
                                            </div>
                                        </div>
                                        <div className="dbox w-100 d-flex align-items-center">
                                            <div className="icon d-flex align-items-center justify-content-center">
                                                <Mailbox size={20} />
                                            </div>
                                            <div className="text ps-3">
                                                <p><span>E-Mail:</span> <a
                                                    href="mailto:info@regenbogenland-westerkappeln.de">info@regenbogenland-westerkappeln.de</a></p>
                                            </div>
                                        </div>
                                        <div className="dbox w-100 d-flex align-items-center">
                                            <div className="icon d-flex align-items-center justify-content-center">
                                                <GlobeAmericas size={20} />
                                            </div>
                                            <div className="text ps-3">
                                                <p><span>Website:</span> <a href="#">regenbogenland-westerkappeln.de</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>

            <ToastContainer
                enableMultiContainer
                containerId="CF"
                position="bottom-center"
                theme="colored"
                hideProgressBar={true}
            />
        </section>
    );
}