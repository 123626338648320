import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LoginPage from "../components/LoginPage";
import useToken from "../components/useToken";
import {ToastContainer} from "react-toastify";
import React, {useState} from "react";
import {BoxArrowRight} from "react-bootstrap-icons";
import Button from "react-bootstrap/Button";
import OverviewTableRow, {RowTypes} from "../components/OverviewTableRow";

export interface Request {
    id: number,
    titel: string,
    anfragedatum: string,
    datum: string,
    zeitslots: string,
    vorname: string,
    nachname: string,
    email: string,
    telefon: number,
    bemerkung: string,
    status: string
}

export default function Administration(props: any) {
    const {token, setToken} = useToken();
    const [user, setUser] = useState(null);
    const [requests, setRequests] = useState<Request[]>([]);
    const [timeSlotsDisabled, setTimeSlotsDisabled] = useState<{ [key: number]: string }>({});

    const onLogout = () => {
        setToken(null);
        setUser(null);
        sessionStorage.removeItem("token");
        props.toastfunction("AD", 1, "Bis bald!");
    };

    const fetchEvents = async () => {
        try {
            let temp: { [key: number]: string } = {};

            await fetch("https://regenbogenland-westerkappeln.de/backend/fetchoverviewevents.php", {
                method: "POST"
            })
                .then((response) => {
                    if(response.ok) {
                        return response.json();
                    }

                    throw new Error("Fehler beim Laden der Buchungen");
                })
                .then((data) => {
                    if(data.data) {
                        setRequests(data.data);

                        data.data.forEach((request: Request) => {
                            temp[request.id] = request.zeitslots;
                        });

                        setTimeSlotsDisabled(temp);
                    } else {
                        if(data.fehler) {
                            throw new Error(data.fehler);
                        } else {
                            throw new Error("Fehler beim Laden der Buchungen");
                        }
                    }
                });
        } catch(error: any) {
            props.toastfunction("AD", 2, error.message);
        }
    }

    const onSubmit = async (data: any, id: number, type: RowTypes) => {
        let body = {
            id: id,
            titel: data[id + "_titel"],
            zeitslots: data[id + "_zeitslots"],
            nachricht: data[id + "_nachricht"],
            status: data[id + "_status"],
            type: type
        };

        await fetch("https://regenbogenland-westerkappeln.de/backend/processrequest.php", {
            method: "POST",
            body: JSON.stringify(body)
        })
            .then((response) => response.json())
            .then((data) => {
                if(data.erfolg === "Ok") {
                    props.toastfunction("AD", 1, "Bearbeitung erfolgreich");

                    if(data.bemerkung) {
                        props.toastfunction("AD", 3, data.bemerkung);
                    }
                } else {
                    props.toastfunction("AD", 2, "Bei der Bearbeitung ist ein Fehler aufgetreten. Grund: " + data.fehler);
                }
            }).catch(error => {
                props.toastfunction("AD", 2, "Bei der Bearbeitung ist ein Fehler aufgetreten");
        });
    };

    return(
        <>
            {token && user ? (
                <Container fluid className="mt-4" style={{minWidth: "1400px"}}>
                    <Row className="text-end">
                        <Col>
                            <Button className="text-white" variant="primary" onClick={onLogout}>
                                Ausloggen
                                <BoxArrowRight style={{marginLeft: "6px"}} />
                            </Button>
                        </Col>
                    </Row>
                    <Row className="text-center">
                        <Col>
                            <h2>Regenbogenland Westerkappeln</h2>
                        </Col>
                    </Row>
                    <Row className="text-center">
                        <Col>
                            <h3>Terminverwaltung</h3>
                        </Col>
                    </Row>
                    <Row className="text-center">
                        <Col>
                            <h5>
                                {new Date().toLocaleDateString("de-DE", {year: "numeric", month: "2-digit", day: "2-digit"})}
                            </h5>
                        </Col>
                    </Row>

                    {requests && requests.length > 0 ? (
                        <Container fluid className="text-center">
                            {requests.filter(request => request.status === "WARTEND").length > 0 &&
                                <>
                                    <Row className="text-start mt-5">
                                        <Col xs={12}>
                                            <h4>Noch nicht bearbeitete Anfragen beantworten:</h4>
                                        </Col>
                                    </Row>

                                    {requests
                                        .filter(request => request.status === "WARTEND")
                                        .map((request) =>
                                            <OverviewTableRow
                                                key={"row_" + request.id}
                                                request={request}
                                                timeSlotsDisabled={timeSlotsDisabled}
                                                type={RowTypes.NEW}
                                                fetchFunction={fetchEvents}
                                                onSubmit={onSubmit}
                                            />
                                        )
                                    }
                                </>
                            }

                            {requests.filter(request => request.status === "ZUGESAGT" || request.status === "ABGELEHNT").length > 0 &&
                                <>
                                    <Row className="text-start" style={{marginTop: "8%"}}>
                                        <Col xs={12}>
                                            <h4>Bereits beantwortete Anfragen bearbeiten:</h4>
                                        </Col>
                                    </Row>

                                    {requests
                                        .filter(request => request.status === "ZUGESAGT" || request.status === "ABGELEHNT")
                                        .map((request) =>
                                            <OverviewTableRow
                                                key={"row_" + request.id}
                                                request={request}
                                                timeSlotsDisabled={timeSlotsDisabled}
                                                type={RowTypes.EDITED}
                                                fetchFunction={fetchEvents}
                                                onSubmit={onSubmit}
                                            />
                                        )
                                    }
                                </>
                            }
                        </Container>
                    )
                    : (
                    <Row className="mt-3 text-center">
                        <Col>
                            Aktuell liegen keine neuen Reservierungen vor
                        </Col>
                    </Row>)}
                </Container>
                )
                : (
                <LoginPage
                    setToken={setToken}
                    setUser={setUser}
                    fetchFunction={fetchEvents}
                    toastfunction={props.toastfunction}
                />
                )
            }

            <ToastContainer
                enableMultiContainer
                containerId="AD"
                position="bottom-center"
                theme="colored"
                hideProgressBar={true}
            />
        </>
    );
}