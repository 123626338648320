import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
    CashStack,
    Flower1,
    HouseFill,
    MortarboardFill,
    PersonFillAdd,
    PuzzleFill,
    StarFill
} from "react-bootstrap-icons";

export default function SupporterOverview() {

    const onCardClick = (e: any) => {
        e.preventDefault();
        document.getElementById("supporterdetails")?.scrollIntoView({ behavior: "smooth"});
    };

    // Neue Rows brauchen neben der gy-4 Klasse auch die mt-2 Klasse, damit der Abstand passt
    return(
        <section id="supporter" className="bg-secondary">
            <Container className="section-title" data-aos="fade-up" data-aos-anchor-placement="top-center">
                <h2>Akteure</h2>
            </Container>

            <Container data-aos="fade-up" data-aos-delay="150" data-aos-anchor-placement="top-center">
                <Row className="gy-4">
                    <Col xl={3} md={6} className="d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                        <div className="supporterCard shadow bg-white px-3 py-5 w-100 h-100" style={{transition: "all ease-in-out 0.4s"}}>
                            <PuzzleFill size={30} style={{marginBottom: "10px", transition: "0.3s"}}/>
                            <h4 style={{marginBottom: "14px", fontWeight: "500"}}>
                                <a
                                    className="text-decoration-none"
                                    style={{transition: "ease-in-out 0.3s"}}
                                    onClick={(event) => onCardClick(event)}
                                    href="/">
                                        Ev. Familienzentrum am Kapellenweg
                                </a>
                            </h4>
                            <p style={{marginBottom: "0", lineHeight: "24px", fontSize: "14px"}}></p>
                        </div>
                    </Col>
                    <Col xl={3} md={6} className="d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                        <div className="supporterCard shadow bg-white px-3 py-5 w-100 h-100" style={{transition: "all ease-in-out 0.4s"}}>
                            <MortarboardFill size={30} style={{marginBottom: "10px", transition: "0.3s"}}/>
                            <h4 style={{marginBottom: "14px", fontWeight: "500"}}>
                                <a
                                    className="text-decoration-none"
                                    style={{transition: "ease-in-out 0.3s"}}
                                    onClick={(event) => onCardClick(event)}
                                    href="/">
                                    Gesamtschule Lotte-Westerkappeln
                                </a>
                            </h4>
                            <p style={{marginBottom: "0", lineHeight: "24px", fontSize: "14px"}}></p>
                        </div>
                    </Col>
                    <Col xl={3} md={6} className="d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                        <div className="supporterCard shadow bg-white px-3 py-5 w-100 h-100" style={{transition: "all ease-in-out 0.4s"}}>
                            <HouseFill size={30} style={{marginBottom: "10px", transition: "0.3s"}}/>
                            <h4 style={{marginBottom: "14px", fontWeight: "500"}}>
                                <a
                                    className="text-decoration-none"
                                    style={{transition: "ease-in-out 0.3s"}}
                                    onClick={(event) => onCardClick(event)}
                                    href="/">
                                    Westerkappelner Elterninitative für Spielen und Erleben e.V.
                                </a>
                            </h4>
                            <p style={{marginBottom: "0", lineHeight: "24px", fontSize: "14px"}}></p>
                        </div>
                    </Col>
                    <Col xl={3} md={6} className="d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                        <div className="supporterCard shadow bg-white px-3 py-5 w-100 h-100" style={{transition: "all ease-in-out 0.4s"}}>
                            <Flower1 size={30} style={{marginBottom: "10px", transition: "0.3s"}}/>
                            <h4 style={{marginBottom: "14px", fontWeight: "500"}}>
                                <a
                                    className="text-decoration-none"
                                    style={{transition: "ease-in-out 0.3s"}}
                                    onClick={(event) => onCardClick(event)}
                                    href="/">
                                    Ev. Kirchengemeinde Westerkappeln
                                </a>
                            </h4>
                            <p style={{marginBottom: "0", lineHeight: "24px", fontSize: "14px"}}></p>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-5 mb-4 text-center" style={{fontSize: "115%"}}>
                    <Col>
                        <i>Wir bedanken uns herzlich bei allen Förderern, Spendern und Unterstützern für Ihr Engagement!</i>
                    </Col>
                </Row>
                <Row className="gy-4 justify-content-md-center">
                    <Col xl={4} md={6} className="d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                        <div className="supporterCard shadow bg-white px-3 py-5 w-100 h-100" style={{transition: "all ease-in-out 0.4s"}}>
                            <StarFill size={30} style={{marginBottom: "10px", transition: "0.3s"}}/>
                            <h4 style={{marginBottom: "14px", fontWeight: "500"}}>
                                <a
                                    className="text-decoration-none"
                                    style={{transition: "ease-in-out 0.3s"}}
                                    onClick={(event) => onCardClick(event)}
                                    href="/">
                                    Förderer
                                </a>
                            </h4>
                            <p style={{marginBottom: "0", lineHeight: "24px", fontSize: "14px"}}>Projekte im Regenbogenland werden finanziell durch verschiedenste Stiftungen und durch Bewilligung öffentlicher Mittel möglich gemacht.</p>
                        </div>
                    </Col>
                    <Col xl={4} md={6} className="d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                        <div className="supporterCard shadow bg-white px-3 py-5 w-100 h-100" style={{transition: "all ease-in-out 0.4s"}}>
                            <PersonFillAdd size={30} style={{marginBottom: "10px", transition: "0.3s"}}/>
                            <h4 style={{marginBottom: "14px", fontWeight: "500"}}>
                                <a
                                    className="text-decoration-none"
                                    style={{transition: "ease-in-out 0.3s"}}
                                    onClick={(event) => onCardClick(event)}
                                    href="/">
                                    Unterstützer
                                </a>
                            </h4>
                            <p style={{marginBottom: "0", lineHeight: "24px", fontSize: "14px"}}>Ehrenamtliche Helfer, aber auch Einrichtungen und Firmen bereichern das Regenbogenland tatkräftig.</p>
                        </div>
                    </Col>
                    <Col xl={4} md={6} className="d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                        <div className="supporterCard shadow bg-white px-3 py-5 w-100 h-100" style={{transition: "all ease-in-out 0.4s"}}>
                            <CashStack size={30} style={{marginBottom: "10px", transition: "0.3s"}}/>
                            <h4 style={{marginBottom: "14px", fontWeight: "500"}}>
                                <a
                                    className="text-decoration-none"
                                    style={{transition: "ease-in-out 0.3s"}}
                                    onClick={(event) => onCardClick(event)}
                                    href="/">
                                    Spender
                                </a>
                            </h4>
                            <p style={{marginBottom: "0", lineHeight: "24px", fontSize: "14px"}}>Private Spenden sowie Firmenspenden ermöglichen es uns, Projekte im Regenbogenland umzusetzen.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}