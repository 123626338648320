import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Controller, useForm} from "react-hook-form";
import Form from "react-bootstrap/Form";
import {FloatingLabel} from "react-bootstrap";
import React, {useEffect} from "react";
import Button from "react-bootstrap/Button";
import jwt from "jwt-decode";

export default function LoginPage(props: any) {
    const {
        handleSubmit,
        control,
        reset,
        formState: { isSubmitSuccessful, errors, dirtyFields }} = useForm({mode: "onChange"});

    useEffect(() => {
        if (!isSubmitSuccessful) { return }

        reset({
            benutzername: "",
            passwort: ""
        });
    }, [isSubmitSuccessful, reset]);

    const onSubmit = async (data: any) => {
        let body = {
            username: data.benutzername,
            password: data.passwort
        };

        try {
            await fetch("https://regenbogenland-westerkappeln.de/backend/authentication/api/login", {
                method: "POST",
                body: JSON.stringify(body)
            })
                .then((response) => {
                    if(response.ok) {
                        return response.json();
                    }

                    throw new Error("Fehler beim Loginversuch");
                })
                .then((data) => {
                    if(data.status) {
                        props.setToken(data.status);

                        const user: any = jwt(data.status);
                        props.setUser(user.user);
                        props.toastfunction("AD", 1, "Willkommen, " + user.user.vorname + "!");
                    } else {
                        if(data.fehler) {
                            throw new Error(data.fehler);
                        } else {
                            throw new Error("Fehler beim Loginversuch");
                        }
                    }
            });

            await props.fetchFunction();
        } catch(error: any) {
            props.toastfunction("AD", 2, error.message);
        }
    }

    return(
        <Container fluid className="text-center mt-5">
            <Container className="bg-secondary shadow p-5">
                <Form id="contactForm" noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col>
                            <h3>Loggen Sie sich bitte ein</h3>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col>
                            <FloatingLabel label="Benutzername" className="mb-3">
                                <Controller
                                    name="benutzername"
                                    control={control}
                                    rules={{
                                        required: {value: true, message: "Bitte geben Sie einen Benutzernamen ein"}
                                    }}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Form.Control
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            isInvalid={!!errors.benutzername}
                                            isValid={dirtyFields.benutzername && !errors.benutzername}
                                            ref={ref}
                                            type="text"
                                            placeholder="Max"
                                        />
                                    )}
                                    defaultValue=""
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.benutzername && <p>{errors.benutzername?.message?.toString()}</p>}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FloatingLabel label="Passwort" className="mb-3">
                                <Controller
                                    name="passwort"
                                    control={control}
                                    rules={{
                                        required: {value: true, message: "Bitte geben Sie ein Passwort ein"}
                                    }}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Form.Control
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            isInvalid={!!errors.passwort}
                                            isValid={dirtyFields.passwort && !errors.passwort}
                                            ref={ref}
                                            type="password"
                                            placeholder="Passwort"
                                        />
                                    )}
                                    defaultValue=""
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.passwort && <p>{errors.passwort?.message?.toString()}</p>}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button variant="primary" style={{color: "#FFFFFF"}} type="submit">
                                Einloggen
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </Container>
    );
}